import axiosApi from '../../helpers/api-helper';

export const authenticateUser = ({ username, password }) => {
  return axiosApi.post('/users/login', { username, passcode: password });
};

export const getSessionDetails = () => {
  return axiosApi.get('/users/session-details');
};

export const logoutUser = () => {
  return axiosApi.post('/users/logout');
};
