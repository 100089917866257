import React from 'react';
import { useSelector } from 'react-redux';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import BusyLoaderSimple from '../../../components/loading-spinner/busy-loader-simple';
import Box from '@mui/material/Box';
import DeviceInfoCard from '../device-info-card/device-info-card';
import lodash from 'lodash';

// eslint-disable-next-line react/prop-types
function DeviceHealthInfo() {
  const {
    deviceDetails, actionInProgress,
  } = useSelector((state) => state.deviceInfo);

  return (
    <>
      {
        actionInProgress ? <BusyLoaderSimple/> : (
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={12} lg={6}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                    >
                      <DeviceInfoCard title={'General Info'}
                                      data={lodash.pick(deviceDetails, ['device_id', 'host_name'])}/>
                      <DeviceInfoCard title={'User Session Info'}
                                      data={lodash.pick(deviceDetails, ['current_auth_code',
                                        'current_auth_code_created_at',
                                        'previous_auth_code',
                                        'active_user_session_id',
                                        'last_user_session_end_time'])}/>
                      <DeviceInfoCard title={'CPU Info'} data={deviceDetails.cpu_info}/>
                      <DeviceInfoCard title={'Network Devices'} data={deviceDetails.network_devices}/>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                    >
                      <DeviceInfoCard title={'Timestamps'}
                                      data={lodash.pick(deviceDetails, ['last_report_timestamp', 'updated_timestamp'])}
                      />
                      <DeviceInfoCard title={'Facility Details'}
                                      data={lodash.pick(deviceDetails, ['facility_id', 'facility_name'])}/>
                      <DeviceInfoCard title={'Memory Info'} data={deviceDetails.memory_info}/>
                      <DeviceInfoCard title={'Temperature Info'} data={deviceDetails.temperature_info}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Slide>
        )
      }
    </>
  );
}

export default React.memo(DeviceHealthInfo);
