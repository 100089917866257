import * as React from 'react';
import Stack from '@mui/material/Stack';
import PropagateLoader from 'react-spinners/PropagateLoader';

export default function BusyLoaderSimple() {
  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  return (
    <Stack style={{ height: '100%', marginTop: '4rem' }}>
      <PropagateLoader
        color="rgba(54, 142, 214, 0.2)"
        loading={true}
        cssOverride={override}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </Stack>
  );
}
