import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { isPlainObject, startCase } from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

function formatText(title, text) {
  if (title === 'CPU Info') {
    return `${text}%`;
  }
  if (isPlainObject(text)) {
    return JSON.stringify(text, null, 2);
  }

  return text;
}

// eslint-disable-next-line react/prop-types
const DeviceInfoCard = ({ title = '', data = {} }) => {
  return (
    <Card variant="outlined" sx={{ marginBottom: '2rem' }}>
      <CardContent>
        {
          title && (
            <Typography variant="h5" component="div" mb="2rem" color="grey">
              {title}
            </Typography>
          )
        }
        <Grid container spacing={2}>
          {
            title === 'Timestamps' ? (
              <Grid item xs={6}>
                <Typography variant="h6" component="h5">
                  {'Data last refreshed'}
                </Typography>
                <Typography
                  variant="body2"
                  component="p" sx={{ color: dayjs().diff(data.last_report_timestamp, 's') > 120 ? 'red' : 'black' }}
                >
                  {dayjs(data.last_report_timestamp).fromNow(false)}
                </Typography>
              </Grid>
            ) : null
          }

          {
            data && typeof data === 'object' &&
            Object.keys(data).map((key, index) => {
              return (
                <Grid item xs={6} key={index}>
                  <Typography variant="h6" component="h5">
                    {startCase(key)}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {formatText(title, data[key])}
                  </Typography>
                </Grid>
              );
            })
          }
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DeviceInfoCard;
