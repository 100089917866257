import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_DEVICE_LIST,
  getDeviceListSuccess,
  getDeviceListInProgress,
  getDeviceListFailed,
  getDeviceDetailsSuccess,
  getDeviceDetailsInProgress,
  getDeviceDetailsFailed, GET_DEVICE_DETAILS,
  OPERATE_MOTORS,
  GET_SERVICE_STATUS,
  getServiceStatusInProgress,
  getServiceStatusSuccess,
  getServiceStatusFailed,
} from './devices.reducer';
import { get } from 'lodash';

import { apiGetDeviceList, apiGetDeviceDetails, apiSetDeviceCommand, apiGetServiceStatus } from './devices.api';

function* sagaGetDeviceList() {
  try {
    yield put(getDeviceListInProgress());
    const response = yield call(apiGetDeviceList);
    const responseData = get(response, 'data.deviceList');
    yield put(getDeviceListSuccess({ data: { devices: [...responseData] } }));
  } catch (error) {
    yield put(getDeviceListFailed());
  }
}

function* sagaGetDeviceDetails({ payload }) {
  const { deviceId } = payload;
  try {
    yield put(getDeviceDetailsInProgress());
    const response = yield call(apiGetDeviceDetails, { deviceId });
    const responseData = get(response, 'data');
    yield put(getDeviceDetailsSuccess({ data: { deviceDetails: responseData.data } }));
  } catch (error) {
    yield put(getDeviceDetailsFailed());
  }
}

function* sagaOperateMotors({ payload }) {
  const { deviceId, command } = payload;
  try {
    let response = yield call(apiSetDeviceCommand, { deviceId, command });
    let responseData = get(response, 'data');
    response = yield call(apiGetDeviceDetails, { deviceId });
    responseData = get(response, 'data');
    yield put(getDeviceDetailsSuccess({ data: { deviceDetails: responseData.data } }));
  } catch (error) {
    yield put(getDeviceDetailsFailed());
  }
}

function* sagaGetServiceStatus({ payload }) {
  const { deviceId } = payload;
  try {
    yield put(getServiceStatusInProgress());
    const response = yield call(apiGetServiceStatus, { deviceId });
    const { status = {}, updated_timestamp: updatedTimestamp = '' } = get(response, 'data');
    yield put(getServiceStatusSuccess({ statuses: status, deviceId, lastReceived: updatedTimestamp }));
  } catch (error) {
    yield put(getServiceStatusFailed());
  }
}

function* sagaDeviceInfo() {
  yield takeLatest(GET_DEVICE_LIST, sagaGetDeviceList);
  yield takeLatest(GET_DEVICE_DETAILS, sagaGetDeviceDetails);
  yield takeLatest(OPERATE_MOTORS, sagaOperateMotors);
  yield takeLatest(GET_SERVICE_STATUS, sagaGetServiceStatus);
}

export default sagaDeviceInfo;
