import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const ItemCard = ({ title, description, route }) => {
  const navigate = useNavigate();
  return (
    <Card sx={{ minWidth: 275, cursor: 'pointer' }} onClick={() => navigate(route)}>
      <CardContent>
        <CardHeader title={title} subheader={description} />
      </CardContent>
    </Card>
  );
};

const Dashboard = () => {
  return (
    <div>
      <Box
        sx={{
          textAlign: 'center',
          marginBottom: '2rem',
        }}
      >
        <h1>Dashboard</h1>
      </Box>
      <Stack direction="row" spacing={2} sx={{ marginTop: '3rem' }}>
        <ItemCard title={'Devices'} description={'View Device Info'} route={'/device-list'}/>
        <ItemCard title={'Users'} description={'View User Info'} route={'/users'}/>
      </Stack>
    </div>
  );
};

export default Dashboard;
