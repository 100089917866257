import io from 'socket.io-client';

// const SOCKET_URL = 'https://web4.kamidi.com/admin-dashboard';
const SOCKET_URL = (process.env.NODE_ENV === 'production')
  ? 'https://ball-machine.waltair.io/admin-dashboard'
  : 'https://ball-machine.waltair.io/admin-dashboard'; // 'https://web4.kamidi.com/admin-dashboard';

export const socketInstance = ({ authToken, deviceId }) => {
  return io(SOCKET_URL, {
    transports: ['websocket', 'polling'],
    upgrade: false,
    cors: {
      origin: ['http://localhost:3000', 'https://admin.waltair.io'],
    },
    auth: {
      auth_token: authToken,
      device_id: deviceId,
    },
    autoConnect: false,
  });
};
