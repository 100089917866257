import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import lodash from 'lodash';

const API_BASE_URL = 'https://dev-ball-machine.waltair.io/api/v2'; // Replace with your actual API base URL

const PoseEstimationLabeling = () => {
  const [currentClip, setCurrentClip] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ message: '', severity: 'info' });
  // console.log('currentClip:', currentClip);
  const {
    sessionDetails,
  } = useSelector((state) => state.auth);
  // console.log('sessionDetails:', sessionDetails);

  const fetchClip = async () => {
    try {
      setIsLoading(true);
      setNotification({ message: 'Fetch in progress', severity: 'info' });
      const response = await fetch(`${API_BASE_URL}/pose-estimation-classification`);
      if (!response.ok) {
        throw new Error('Failed to fetch clip');
      }
      const data = await response.json();
      // console.log('Fetched clip:', data);
      setIsLoading(false);
      setNotification({ message: 'Successfully fetched new clip!', severity: 'success' });
      return lodash.get(data, 'record', {});
    } catch (error) {
      console.error('Error fetching clip:', error);
      setIsLoading(false);
      setNotification({ message: 'Failed to fetch clip', severity: 'error' });
      return null;
    }
  };

  useEffect(() => {
    const fetchCurrentClip = async () => {
      const clip = await fetchClip();
      if (clip) {
        setCurrentClip(clip);
      }
    };
    fetchCurrentClip();
  }, []);

  const sendAction = async (action) => {
    try {
      setIsLoading(true);
      setNotification({ message: 'Update in progress', severity: 'info' });
      const response = await fetch(`${API_BASE_URL}/pose-estimation-classification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileName: currentClip.video_clip_file_name,
          classification: action,
          userId: `${sessionDetails.first_name}_${sessionDetails.last_name}`,
        }),
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error('Failed to send action');
      }
      // console.log(`Action ${action} sent successfully.`);
      setNotification({
        message: `Successfully updated the clip classification to ${action.toUpperCase()}`,
        severity: 'success'
      });
    } catch (error) {
      setIsLoading(false);
      console.error('Error sending action:', error);
      setNotification({ message: 'Failed to update!', severity: 'error' });
    }
  };

  const handleNext = async () => {
    const nextClip = await fetchClip();
    if (nextClip) {
      setCurrentClip(nextClip);
    }
  };

  /* const handlePrevious = async () => {
    const prevClip = await fetchClip();
    if (prevClip) {
      setCurrentClip(prevClip);
    }
  }; */

  return (
    <Container maxWidth="xl" style={{ marginTop: '2rem', textAlign: 'center' }}>
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        <Grid container spacing={2}>
          <Grid size={12} textAlign={'center'} style={{ width: '100%' }}>
            <Typography variant="h4" gutterBottom>
              {currentClip.video_clip_file_name}
            </Typography>
          </Grid>
          <Grid size={12} textAlign={'center'} style={{ width: '100%', margin: '1rem 0' }}>
            {
              notification && notification.message ? (
                <Alert severity={notification.severity}>{notification.message}</Alert>
              ) : null
            }
          </Grid>
          <Grid size={12} textAlign={'center'} style={{ width: '100%', margin: '1rem 0', border: '0 solid blue' }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Box width={{ xs: '100%', md: '80%' }}>
                <video
                  controls
                  muted
                  style={{ width: '100%', height: 'auto', marginBottom: '1rem' }}
                  src={currentClip.video_clip_file_url}
                />
              </Box>
              <Box width={{ xs: '100%', md: '20%', textAlign: 'center' }}>
                {
                  currentClip.video_clip_file_name && (
                    <>
                      <Typography variant="h6" gutterBottom>
                        Action Classification
                      </Typography>
                      <Stack spacing={2}>
                        {['forehand', 'backhand', 'serve', 'overhead_smash',
                          'no_action', 'missed',
                          'not_sure'].map((action) => (
                          <Button
                            key={action}
                            variant="outlined"
                            color="primary"
                            disabled={isLoading}
                            onClick={() => sendAction(action)}
                          >
                            {action.replace('_', ' ').toUpperCase()}
                          </Button>
                        ))}
                      </Stack>
                      <Stack spacing={2} sx={{ marginTop: '4rem' }}>
                        {/* <Grid size={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePrevious}
                      disabled={isLoading}
                    >
                      Previous
                    </Button>
                  </Grid> */}
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={isLoading}
                          >
                            Next Clip
                          </Button>
                        </Grid>
                      </Stack>
                    </>
                  )
                }
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PoseEstimationLabeling;
