import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

const Timeout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnIdle = () => {
    if (location.pathname === '/login' || location.pathname === '/logout') {
      return;
    }
    navigate('/logout');
  };

  useIdleTimer({
    timeout: 20 * 60 * 1000,
    onIdle: handleOnIdle,
    debounce: 250,
    promptBeforeIdle: 3000,
  });

  return <div />;
};

export default Timeout;
