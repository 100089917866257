import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Button, CardActions } from '@mui/material';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  GET_DEVICE_LIST,
  // getDeviceListSuccess,
} from '../devices.reducer';
import BusyLoaderSimple from '../../../components/loading-spinner/busy-loader-simple';

function buildCards(devices, navigate) {
  if (!devices || !Array.isArray(devices)) {
    return [];
  }

  return devices.map((device) => (
    <Grid item xs={4} key={device.device_id}>
      <Stack direction="column" spacing={1}>
        <Card sx={{ maxWidth: 350, padding: '1rem' }} variant="outlined">
          <Typography variant="h5" component="div">
            {device.facility_name} &nbsp;
          </Typography>
          <Box textAlign="right">
            {
              device.current_user_session_id || device.current_command === 'start_motors' ? (
                <Chip label="Session in progress" color="primary" size="small"/>
              ) : (<Chip label="Inactive" color="warning" size="small"/>)
            }
          </Box>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {`Host name: ${device.host_name}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`Device Id: ${device.device_id}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`Current Auth Code: ${device.current_auth_code}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`Last updated at: ${dayjs(device.last_report_timestamp).format('DD-MMM-YYYY h:mm:ss A')}`}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary"
                    onClick={() => navigate(`/devices/${device.device_id}`)}>
              View Details
            </Button>
          </CardActions>
        </Card>
      </Stack>
    </Grid>
  ));
}

const DeviceList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    devices,
    actionInProgress,
  } = useSelector((state) => state.deviceInfo);

  /* const handleDeviceList = useCallback((data) => {
    dispatch(getDeviceListSuccess({ data: { devices: [...data] } }));
  }, []); */

  useEffect(() => {
    dispatch({
      type: GET_DEVICE_LIST,
    });
  }, []);

  /* useEffect(() => {
    socket.on('device-list', handleDeviceList);

    return () => {
      socket.off('device-list', handleDeviceList);
    };
  }, [socket, handleDeviceList]); */

  const cards = buildCards(devices, navigate);

  return (
    <Stack direction="column" spacing={2} mb="2rem">
      <Box
        sx={{
          textAlign: 'center',
          marginBottom: '2rem'
        }}
      >
        <h1>Device List</h1>
      </Box>
      {
        actionInProgress ? <BusyLoaderSimple/> : (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {cards}
            </Grid>
          </Box>
        )
      }
    </Stack>
  );
};

export default DeviceList;
